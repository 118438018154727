import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
} from "remix";

import { ChakraProvider, Box, Heading, extendTheme } from "@chakra-ui/react";

import { getDataByCompany } from "~/data/_data";

export let loader = async ({ request }) => {
  const host = request.headers.get("host");
  return { website: getDataByCompany({ host }) };
};

export function Analytics({ settings }) {
  return (
    <>
      {settings && settings.analyticsId && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${settings.analyticsId}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${settings.analyticsId}');
        `,
            }}
          />
        </>
      )}
    </>
  );
}

function Document({ children, title = "FoodSite by A Gestoria" }) {
  const data = useLoaderData();
  const settings = data?.website?.settings;
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <title>{title}</title>
        <Links />
        <Analytics settings={settings} />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const theme = extendTheme({ colors });

export default function App() {
  return (
    <Document>
      <ChakraProvider theme={theme}>
        <Outlet />
      </ChakraProvider>
    </Document>
  );
}

// How ChakraProvider should be used on CatchBoundary
export function CatchBoundary() {
  const caught = useCatch();
  console.log({ status: caught.status, statusText: caught.statusText });

  return (
    <Document title={`${caught.status} ${caught.statusText}`}>
      <ChakraProvider theme={theme}>
        {caught.status === 404 ? (
          <Box>
            <Heading as="h1" bg="blue.200" fontSize="4xl" pt="16">
              Página não encontrada!
            </Heading>
            <Heading as="h2" bg="blue.200" fontSize="2xl" pb="16" pt="8">
              Por favor, verifique o endereço e tente novamente.
            </Heading>
          </Box>
        ) : (
          <Box>
            <Heading as="h1" bg="purple.600">
              [CatchBoundary]: {caught.status} {caught.statusText}
            </Heading>
          </Box>
        )}
      </ChakraProvider>
    </Document>
  );
}

// How ChakraProvider should be used on ErrorBoundary
export function ErrorBoundary({ error }) {
  return (
    <Document title="Error!">
      <ChakraProvider>
        <Box>
          <Heading as="h1" bg="blue.500">
            [ErrorBoundary]: There was an error: {error.message}
          </Heading>
        </Box>
      </ChakraProvider>
    </Document>
  );
}
